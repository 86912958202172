<template>
  <div>
    <el-dialog
      v-if="gardener.customers > 0"
      title="Delete Gardener"
      :visible.sync="shouldShow"
      append-to-body
      @close="closeEvent"
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row>
          <el-col :span="24">
            <p class="mb-10">
              This gardener,
              <span class="text-grey-primary text-bold">{{
                gardener.name
              }}</span>
              still has
              <span class="text-grey-primary text-bold">{{
                gardener.customers
              }}</span>
              customers assigned. Please select a new Gardener to reassign these
              customers to
            </p>
            <el-form-item
              label=""
              prop="transfer_gardener_id"
              :rules="validateField()"
            >
              <el-select
                v-model="form.transfer_gardener_id"
                filterable
                placeholder="Select gardener"
              >
                <el-option
                  v-for="(gardener, index) in gardeners"
                  :key="index"
                  :label="gardener.name"
                  :value="gardener.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button
          type="danger"
          :disabled="form.transfer_gardener_id === null"
          :loading="deleting"
          @click="deleteGardener"
          >Delete and Reassign</el-button
        >
      </span>
    </el-dialog>
    <eden-confirm-dialog
      v-else
      title="Delete Gardener"
      button-type="danger"
      button-text="Delete"
      :show.sync="shouldShow"
      @confirm="deleteGardener"
    >
      <p>
        Are you sure you want to delete this gardener, <br />
        <span class="text-grey-primary text-bold">{{
          formatName(gardener.name)
        }}</span>
        ?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import gardeners from "@/requests/gardeners/gardener";
import * as actions from "@/store/action-types";

export default {
  name: "GardenerDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gardener: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        transfer_gardener_id: null,
      },
      deleting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  watch: {
    show() {
      if (this.show && !this.gardeners.length) {
        this.$store.dispatch(actions.GET_GARDENERS_LIST);
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    deleteGardener() {
      this.deleting = true;
      gardeners
        .delete(this.gardener.id, this.form)
        .then((response) => {
          if (response.data.status) {
            this.$emit("success");
            this.$message.success(response.data.message);
            this.shouldShow = false;
          }
        })
        .catch((error) => {
          this.deleting = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
p {
  line-height: 1.8;
}
</style>
